import React, {useState} from "react"
import {Button, ButtonGroup, Col, Container, Form, ProgressBar, Row} from "react-bootstrap"
import {animated, useSprings} from 'react-spring'

import Layout from "../components/layout"
import SEO from "../components/seo"
import QOLQuestion from "../components/qol-question";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import {netlifyFormHandle} from "../utils/form";
import Name from "../components/assessment/name";
import Symptoms from "../components/assessment/symptoms";

const categories = {
	occupational: 'Occupational',
	health: 'Health',
	emotional: 'Emotional',
	sexual: 'Sexual',
}

const qolQuestions = [
	{
		text: 'I am able to control things in my life that are important to me.',
		category: categories.emotional,
		affirmative: true,
	},
	{
		text: 'I feel challenged by my work or main occupation.',
		category: categories.occupational,
		affirmative: true,
	},
	{
		text: 'I believe my work or main occupation benefits society.',
		category: categories.occupational,
		affirmative: true,
	},
	{
		text: 'I am NOT content with my sexual life.',
		category: categories.sexual,
		affirmative: false,
	},
	{
		text: 'I am content with my romantic life.',
		category: categories.sexual,
		affirmative: true,
	},
	{
		text: 'I have gotten a lot of personal recognition in my community or at my job/main occupation.',
		category: categories.occupational,
		affirmative: true,
	},
	{
		text: 'I am unhappy with my appearance.',
		category: categories.health,
		affirmative: false,
	},
	{
		text: 'My diet is NOT nutritionally sound.',
		category: categories.health,
		affirmative: false,
	},
	{
		text: 'I feel in control of my eating behavior.',
		category: categories.health,
		affirmative: true,
	},
	{
		text: 'Routinely, I engage in active exercise three or more times each week.',
		category: categories.health,
		affirmative: true,
	},
	{
		text: 'My mood is generally depressed.',
		category: categories.emotional,
		affirmative: false,
	},
	{
		text: 'I frequently experience anxiety.',
		category: categories.emotional,
		affirmative: false,
	},
	{
		text: 'Most things that happen to me are out of my control.',
		category: categories.emotional,
		affirmative: false,
	},
	{
		text: 'I am content with the frequency of my sexual interactions with a partner.',
		category: categories.sexual,
		affirmative: true,
	},
	{
		text: 'I currently experience physical discomfort or pain during sexual activity.',
		category: categories.emotional,
		affirmative: false,
	},
	{
		text: 'I believe I have NO control over my physical health.',
		category: categories.health,
		affirmative: false,
	},
	{
		text: 'I am proud of my occupational accomplishments.',
		category: categories.occupational,
		affirmative: true,
	},
	{
		text: 'I consider my life stimulating.',
		category: categories.occupational,
		affirmative: true,
	},
	{
		text: 'I continue to set new personal goals for myself.',
		category: categories.occupational,
		affirmative: true,
	},
	{
		text: 'I expect that good things will happen in my life.',
		category: categories.emotional,
		affirmative: true,
	},
	{
		text: 'I feel physically well.',
		category: categories.health,
		affirmative: true,
	},
	{
		text: 'I feel physically fit.',
		category: categories.health,
		affirmative: true,
	},
	{
		text: 'I continue to set new professional goals for myself.',
		category: categories.occupational,
		affirmative: true,
	},
];

const Assessment = () => {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [scores, setScores] = useState({});
	const [currentStep, setCurrentStep] = useState(0);
	const [completed, setCompleted] = useState(0);

	const nextStep = () => {
		setCompleted(currentStep);
		setCurrentStep(currentStep + 1);
	}
	const handleAssessment = (e) => {
		setLoading(true);
		return netlifyFormHandle(e, `/insights?token=${btoa(email)}`);
	};
	const scale = Object.keys(scores).reduce((acc, key) => {
		const cat = qolQuestions[key].category;
		return {
			...acc,
			[cat]: (acc[cat] || 0) + (qolQuestions[key].affirmative ? scores[key] : 6 - scores[key])
		};
	}, {});
	const steps = [
		(
			<div>
				<div className="h3">
					You are more than your symptoms
				</div>
				<p>
					Your quality of life through and beyond (peri) menopause is
					what matters most so you can experience it on your terms!
				</p>
				<p className="mb-4">
					Find out your quality of life score and how your
					(peri)menopause is affecting it. It’s scientifically validated by
					top menopause experts, 100% confidential and takes less than 3
					minutes to complete
				</p>
				<div className="text-center mb-5">
					<Button size="lg" onClick={nextStep}>Lets get started!</Button>
				</div>
				<p className="text-muted">
					<small>
						*Disclaimer: This assessment is not intended to be a diagnostic tool, it is leveraged to provide
						health information only. If you are experiencing any severe symptom, you should seek medical
						advice.
					</small>
				</p>
			</div>
		),
		<Name onNext={nextStep} />,
		<Symptoms onNext={nextStep} />,
		(
			<div>
				<div className="h4">
					Now let’s talk about other general topics that have proven to impact quality of life.
				</div>
				<p>
					The following questions will be related to your overall, emotional and sexual health as well as your occupational satisfaction.
				</p>
				<div className="text-center mb-5">
					<Button size="lg" onClick={nextStep}>Continue</Button>
				</div>
			</div>
		),
		...qolQuestions.map((question, index) => (
				<QOLQuestion
					question={question.text}
					selected={scores[index]}
					onSelect={(score) => {
						setScores({
							...scores,
							[index]: score
						});
						nextStep();
					}}
				/>
			)),
		(
			<div>
				<h2 className="pt-3">Thank you for taking the assessment.</h2>
				<p>
					Please provide us with your email address which we can use to send you the results.
				</p>
				<Form.Group controlId="formEmail">
					<Form.Label>What is your email address?</Form.Label>
					<Form.Control required size="lg" type="email" name="email" placeholder="eg. sophie@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
					<Form.Text>You will receive your results in your inbox</Form.Text>
				</Form.Group>
				<div className="d-flex justify-content-end mb-5">
					<Button type="submit" size="lg" disabled={loading}>{loading ? (
							<>
								<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
								Loading...
							</>
						) : 'Get my insights'}
					</Button>
				</div>
				<p className="text-muted">
					<small>
						By sharing your email you agree to receive relevant communication
						from Baushe and stay up to date with our news and product launch
					</small>
				</p>
			</div>
		)
	];
	const springs = useSprings(steps.length, steps.map((item, index) => {
		const current = index === currentStep;
		return {
			opacity: (current ? 1 : 0),
			display: current ? 'block' : 'none',
			transform: current ? 'translate3d(0,0,0)' : 'translate3d(0,-50%,0)',
		}
	}));
	const progress = (100 / steps.length) * currentStep;
	return (
		<Layout>
			<SEO title="Menopause Assessment & Health Insights"/>
			<Container className="py-5">
				<Form onSubmit={handleAssessment} action="/" method="POST" data-netlify="true" name="assessment">
					<input type="hidden" value="assessment" name="form-name"/>
					{Object.values(categories).map((key) => (
						<input type="hidden" value={scale[key] || 0} name={`qol_${key}`}/>
					))}
					<Row>
						<Col xs="12" md="6" className="mx-auto">
							<Row className="mb-5">
								<Col className="position-relative">
									{springs.map((props, index) => (
										<animated.div style={props}>{steps[index]}</animated.div>
									))}
								</Col>
							</Row>
							<Row className="align-items-end justify-content-between">
								<Col xs="auto" className="flex-grow-1">
									<small className="text-muted">{progress.toFixed(0)}% completed</small>
									<ProgressBar now={progress} />
								</Col>
								<Col xs="auto">
									<ButtonGroup size="sm">
										<Button className="d-flex align-items-center" disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}><FaArrowUp/></Button>
										<Button className="d-flex align-items-center" disabled={currentStep > completed || currentStep === (steps.length - 1)} onClick={() => setCurrentStep(currentStep + 1)}><FaArrowDown/></Button>
									</ButtonGroup>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Container>
		</Layout>
	)
}

export default Assessment
