import React, { useState } from 'react'
import {Button, Form} from "react-bootstrap";
import {FaCheck} from "react-icons/fa";

const symptomList = [
	'Hot flashes',
	'Sleep Problems',
	'Vaginal Dryness',
	'Depression',
	'Night sweats',
	'Heart palpitations',
	'Discomfort during sex',
	'Reduced sex drive (libido)',
	'Urinating more frequently or urgently',
	'Painful urination',
	'Bloating, constipation or digestive issues',
	'Migraines / headaches',
	'Muscle or joint pain',
	'Anxiety or feeling more worried',
	'Low or depressed mood',
	'Problems with memory and concentration',
  'Low energy or fatigue',
  'Irritability or mood swings',
]

const Symptoms = ({ onNext }) => {
	const [symptoms, setSymptoms] = useState([]);
	const [showMore, setShowMore] = useState(true);
	const onChange = (e) => {
		const newSymptoms = [...symptoms];
		if (e.target.checked) {
			if (symptoms.length >= 3) {
				e.target.checked = false;
				return;
			}
			newSymptoms.push(e.target.value)
		} else {
			newSymptoms.splice(newSymptoms.indexOf(e.target.value), 1)
		}
		setSymptoms(newSymptoms);
	}
	return (
		<div>
			<div className="h5">Which symptoms have the biggest impact on your life?</div>
			<p>This is so we can support you with relevant content after the assessment. Select up to 3 symptoms.</p>
			<div className="d-flex flex-wrap justify-content-between">
				{symptomList.slice(0, showMore ? 6 : symptomList.length).map((symptom, index) => (
					<Form.Check
						key={`symptom-${symptom}`}
						className="mb-2 mr-2 pl-0 flex-grow-1"
						type="checkbox"
						id={`symptom-${index}`}
					>
						<Form.Check.Input
							className="d-none"
							name="symptoms[]"
							value={symptom}
							checked={symptoms.indexOf(symptom) !== -1}
							onChange={onChange}
						/>
						<Form.Check.Label className={`border w-100 text-center rounded p-3 ${symptoms.indexOf(symptom) !== -1 ? 'border-success text-success' : ''}`}>{symptom}</Form.Check.Label>
					</Form.Check>
				))}
			</div>
			<div className="text-center">
				{showMore && <Button variant="link" onClick={() => setShowMore(false)}>Show more</Button>}
			</div>
			<div className="d-flex justify-content-end mr-2">
				<Button disabled={symptoms.length === 0} className="d-flex align-items-center" onClick={onNext}><FaCheck />&nbsp;Ok</Button>
			</div>
		</div>
	);
}

export default Symptoms;