import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";

const Name = ({ onNext }) => {
	const [name, setName] = useState('');
	const [age, setAge] = useState('');
	return (
		<div>
			<h2 className="pt-3">Ready to Learn About Yourself?</h2>
			<p>
				First things first...
			</p>
			<Form.Group controlId="formName">
				<Form.Label>What is your name? (optional)</Form.Label>
				<Form.Control size="lg" type="text" placeholder="Your name" name="name" value={name} onChange={e => setName(e.target.value)} />
				<Form.Text>We use this to personalise your results</Form.Text>
			</Form.Group>
			<Form.Group controlId="formAge">
				<Form.Label>What is your age?</Form.Label>
				<Form.Control size="lg" type="number" placeholder="Your age" min={1} max={150} name="age" value={age} onChange={e => setAge(e.target.value)} />
			</Form.Group>
			<div className="d-flex justify-content-end">
				<Button size="lg" disabled={!age} onClick={onNext}>Start</Button>
			</div>
		</div>
	)
}

export default Name;