import React from "react";
import {Button, ButtonGroup} from "react-bootstrap";

const QOLQuestion = ({ question, onSelect, selected }) => (
	<div>
		<div className="h5 mb-3">{question}</div>
		<ButtonGroup size="lg" className="w-100">
			{[1, 2, 3, 4, 5].map(score => (
				<Button
					key={score}
					variant="outline-primary"
					active={score === selected}
					onClick={() => onSelect(score)}
				>
					{score}
				</Button>
			))}
		</ButtonGroup>
		<div className="d-flex justify-content-between">
			<span>Not true of me</span>
			<span>Moderately true of me</span>
			<span>Very true of me</span>
		</div>
	</div>
)

export default QOLQuestion;